import * as React from "react";
import { HeadFC, PageProps } from "gatsby";
import { useEffect } from "react";
import { navigate } from "@reach/router";

const NotFoundPage: React.FC<PageProps> = () => {
  useEffect(() => {
    navigate("/");
  }, []);
  return null;
};

export default NotFoundPage;

// export const Head: HeadFC = () => <title>Not found</title>
